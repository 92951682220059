<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">User Role</h3>
          <v-btn
            id="add-btn"
            v-if="hasPermCreate()"
            large
            color="primary600Color"
            @click="create"
            class="white-text-button button-bold"
            t-data="add-btn"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Add User roles
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-diglog-update
      :show="isUpdate"
      :status="action.status"
      :close="closePopup"
      :confirm="confirmUpdate"
    />
    <v-alert-confirm
      :show="isDelete"
      :title="'Remove Confirmation'"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      :headline-color="'black'"
      :buttonText="'Remove'"
      :confirm="confirmDelete"
      :icon="''"
    ></v-alert-confirm>

    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>

    <!-- Search and Filter -->
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" t-data="search-panel">
            <search
              :search="searchPhase"
              label="Search by Role, Branch"
              @input="textSearch"
              @clear="clearSearch()"
            ></search>
          </v-col>
          <v-col cols="12" sm="4"></v-col>
          <v-col
            cols="12"
            sm="2"
            class="d-flex justify-end align-center"
            t-data="filter-panel"
          >
            <filter-dropdown @apply="handleApplyFilter()"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          t-data="data-table"
          :loading="isLoading"
          :headers="table.headers"
          :items="rolesList"
          :options.sync="options"
          :items-per-page="table.pageSize"
          :server-items-length="totalRoles"
          :footer-props="{'items-per-page-options': table.itemPerPages}"
          item-key="id"
          :sort-desc="true"
          :must-sort="false"
          class="elevation-1 custom-table localize-table"
          fixed-header
          style="width: 100%"
          :height="
            windowSize.y - heightOptions.header - heightOptions.tableFooter
          "
        >
          <template v-slot:item.org__name="{item}">{{
            item.org_name
          }}</template>
          <template v-slot:item.org="{item}">{{
            item.org.toUpperCase()
          }}</template>
          <template v-slot:item.updated_at="{item}">{{
            dateFmt(item.updated_at)
          }}</template>
          <template v-slot:item.is_active="{item}">
            <v-switch
              t-data="status-switch"
              color="primary200Color"
              v-model="item.is_active"
              :disabled="!hasPermEditByOrg(item.org)"
              class="mb-0 pb-0 custom-switch"
              @change="e => handleSwitchStatusChange(e, item)"
            ></v-switch>
          </template>
          <template v-slot:item.action="{item}">
            <v-icon
              v-if="hasPermEditByOrg(item.org) || hasPermViewByOrg(item.org)"
              t-data="edit-action"
              class="px-2 action-icon"
              @click="rowClick(item.id)"
              >mdi-pencil-outline</v-icon
            >
            <v-icon
              v-else
              t-data="empty-icon"
              class="px-2 action-icon transparent-icon"
            ></v-icon>
            <v-icon
              v-if="hasPermDeleteByOrg(item.org)"
              t-data="delete-action"
              class="px-2 action-icon"
              @click="showDeletePopup(item)"
              >mdi-trash-can-outline</v-icon
            >
            <v-icon
              v-else
              t-data="empty-icon"
              class="px-2 action-icon transparent-icon"
            ></v-icon>
          </template>
        </v-data-table>
      </v-layout>
      <div class="table-footer-prepend" t-data="total-result-content">
        <div class="vertical-center pl-4">
          <b>{{ totalRoles }}</b> Search results
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {
  convertDate,
  hasPermision,
  searchHandler,
  snackbarSuccessContent,
  snackbarFailContent,
  isStaff,
  hasPermissionsByOrgs
} from '@/helper/helper'
import Popup from './UpdateStatusPopup'
import SnackBar from '@/components/SnackBar'
import Search from '@/components/Search'
import {actionEnum} from '@/constants/constants'
import FilterDropdown from '@/views/role-permission/FilterDropdown'
import AlertConfirm from '@/components/AlertConfirm'

export default {
  components: {
    'v-diglog-update': Popup,
    'v-alert-confirm': AlertConfirm,
    Search,
    SnackBar,
    FilterDropdown
  },
  computed: {
    ...mapState('rolePermission', [
      'isDelete',
      'rolesList',
      'isLoading',
      'totalRoles',
    ]),
    user_count() {
      return this.$store.state.rolePermission.deleteObj.user_count
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    }
  },
  created() {
    this.setOrgInfo()
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    create() {
      this.$router.push({name: 'role-permission-create'})
    },
    async handleSwitchStatusChange(event, row) {
      this.setSelectedId([row])
      const isSuccess = await this.update(event)
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.STATUS_UPDATED)
      } else {
        this.showFailSnackbar(actionEnum.STATUS_UPDATED)
      }
    },
    hasPermCreate() {
      return hasPermision('CanAddRolePermission')
    },
    hasPermEdit() {
      return hasPermision('CanEditRolePermission')
    },
    hasPermDelete() {
      return hasPermision('CanDeleteRolePermission')
    },
    hasPermViewByOrg(org) {
      return isStaff()
        ? true
        : hasPermissionsByOrgs('CanViewRolePermission', [org])
    },
    hasPermEditByOrg(org) {
      return hasPermissionsByOrgs('CanEditRolePermission', [org])
    },
    hasPermDeleteByOrg(org) {
      return hasPermissionsByOrgs('CanDeleteRolePermission', [org])
    },
    clearSearch() {
      this.setTextSearch('')
      this.setPage(1)
      this.fetch()
    },
    async confirmDelete() {
      const isSuccess = await this.deleteRole()
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.REMOVED)
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup(obj) {
      this.setDeleteObj(obj)
      this.setDeletePopup(true)
    },
    async confirmUpdate() {
      this.isUpdate = false
      const isSuccess = await this.update(this.action.status)
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.STATUS_UPDATED)
      } else {
        this.showFailSnackbar(actionEnum.STATUS_UPDATED)
      }
    },
    closePopup() {
      this.isUpdate = false
    },
    dateFmt: date => convertDate(date),
    textSearch(data) {
      this.searchPhase = data
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(this.searchPhase.trim())
        this.options.page = 1
        await this.fetch()
      })
    },
    rowClick(id) {
      this.$router.push({name: 'role-permission-edit', params: {id}})
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    ...mapActions('rolePermission', [
      'deleteRole',
      'fetch',
      'resetList',
      'setDeleteObj',
      'setDeletePopup',
      'setFieldSort',
      'setOrgInfo',
      'setPage',
      'setPageSize',
      'setSelectedId',
      'setSort',
      'setTextSearch',
      'update',
    ])
  },
  beforeRouteLeave(to, from, next) {
    this.resetList()
    next()
  },
  data() {
    return {
      pageName: 'user role',
      heightOptions: {
        header: 312,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0
      },
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: '',
      subTitle: 'This Role will no longer available to all users in this branch after the role removal. This process cannot be undone.<br><br>Are you sure you want to remove?',
      deleteObj: {id: 0, user_count: 0},
      searchPhase: '',
      isUpdate: false,
      options: {},
      action: {
        status: '',
      },
      table: {
        page: 1,
        pageSize: 25,
        itemPerPages: [25, 50, 100],
        headers: [
          {
            text: 'No',
            align: 'left',
            sortable: true,
            value: 'id',
            width: 80
          },
          {
            text: 'Role',
            align: 'left',
            value: 'codename',
            sortable: true,
            width: 160
          },
          {
            text: 'Branch',
            align: 'left',
            value: 'org__name',
            sortable: true,
            width: 240
          },
          {
            text: 'Initial',
            align: 'left',
            value: 'org',
            sortable: true,
            width: 100
          },
          {
            text: 'Permission',
            align: 'left',
            value: 'permission_count',
            sortable: false,
            width: 80
          },
          {
            text: 'Users',
            align: 'left',
            value: 'user_count',
            sortable: false,
            width: 80
          },
          {
            text: 'Updated Date/Time',
            align: 'left',
            value: 'updated_at',
            sortable: true,
            width: 200
          },
          {
            text: 'Status',
            align: 'center',
            value: 'is_active',
            sortable: true,
            width: 100
          },
          {
            text: 'Actions',
            align: 'center',
            value: 'action',
            sortable: false,
            width: 100
          }
        ]
      },
      breadcrumbs: [
        {
          text: 'Role & Permission',
          disabled: true,
          link: true,
          to: '/'
        },
        {
          text: 'User Role',
          disabled: true,
          link: true,
          to: '/'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    },
  }
}
</script>
