<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="450">
      <v-card>
        <v-card-title class="headline">
          <span t-data="headline" class="orange--text">Confirmation</span>
          <v-icon t-data="headline-icon" color="orange">information</v-icon>
        </v-card-title>
        <v-card-text t-data="card-text" v-if="status==='Active'">
          <u>Are you sure you want to change status to <span v-bind:style="{color: 'green', fontSize: '16px'}"> {{status}}</span>?</u>
        </v-card-text>
        <v-card-text t-data="card-text" v-else>
          If you change this role to <span v-bind:style="{color: 'red', fontSize: '16px'}"> {{status}}</span>,
          Users in this role will not be allowed to access with role permission.<br><br>
          <u>Are you sure you want to change?</u>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn t-data="change-btn" color="red darken-1" text @click="comfirmPopup">Change</v-btn>
          <v-btn t-data="cancel-btn" color="black darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'AlertConfirm',
    props: {
      show: {type: Boolean},
      close: {type: Function},
      confirm: {type: Function},
      status: {type: String}
    },
    computed: {
      text: function() {
        return this.status === 'Active'
          ? 'Are you sure you want to change status to Active'
          : 'If you change user status to inactive. Users will not allowed to access to portal. Are you sure you want to change?'
      },
    },
    methods: {
      closePopup() {
        this.close()
      },
      comfirmPopup() {
        this.confirm()
      }
    }
  }
</script>

<style scoped>
</style>
